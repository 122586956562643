<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <q-btn v-if="editable && tabParam.tbmId" class="custom-btn" label="QR 코드" icon="qr_code_scanner" color="black" size="md">
                  <q-popup-proxy>
                    <c-card title="QR 코드 출력" class="cardClassDetailForm">
                      <template slot="card-button">
                        <c-btn label="인쇄" icon="print" @btnClicked="print" />
                      </template>
                      <template slot="card-detail">
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="wrap" action="#" id="printJS-form">
                              <vue-qrcode
                                :value="serverName+'/sai/tbm/tbm?tbmId='
                                + tabParam.tbmId" 
                                :options="{ width: 180 }"
                                class="canvas"
                              >
                              </vue-qrcode>
                              <span class="facilityStyle">
                                <p class="abc" >
                                  <b>{{'-TBM명 : '}}</b>
                                  {{tabParam.tbmName}}
                                  <br>
                                  <b>{{'-작업일 : '}}</b>
                                  {{tabParam.tbmWorkDate }}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </c-card>
                  </q-popup-proxy>
                </q-btn>
                <!-- v-show="disabled" -->
                <c-btn
                  v-show="tabParam.tbmId"
                  label="출력"
                  icon="print"
                  @btnClicked="printTbm"
                />
                <c-btn
                  v-show="editable && !tabParam.tbmId"
                  label="타 TBM 불러오기"
                  icon="save_alt"
                  @btnClicked="copyTbm"
                />
                <c-btn
                  v-show="editable && tabParam.tbmId && !disabled && !isApprDisabled"
                  label="LBLREMOVE"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                />
                <!-- <c-btn
                  v-show="editable && tabParam.tbmId && !disabled"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="tabParam"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="check"
                  @beforeAction="completeTbm"
                  @btnCallback="completeCallback"
                /> -->
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <c-appr-btn 
                  v-if="!isApprDisabled"
                  ref="appr-btn"
                  name="danger-journal-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="saveDataAppr"
                  @callbackApprAction="approvalCallback"
                  @requestAfterAction="getDetail"
                />
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="tabParam"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveTbm"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="TBM번호"
                  name="tbmNo"
                  v-model="tabParam.tbmNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM명"
                  name="tbmName"
                  v-model="tabParam.tbmName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :afterIcon="
                    editable && !disabled
                      ? [
                          {
                            name: 'search',
                            click: true,
                            callbackName: 'searchWorkPermit',
                            color: 'teal',
                          },
                          {
                            name: 'close',
                            click: true,
                            callbackName: 'removeWorkPermit',
                            color: 'red',
                          },
                        ]
                      : null
                  "
                  :editable="editable"
                  :disabled="true"
                  label="작업허가번호"
                  name="permitNo"
                  v-model="tabParam.permitNo"
                  @searchWorkPermit="searchWorkPermit"
                  @removeWorkPermit="removeWorkPermit"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="tabParam.plantCd"
                  @datachange="datachangePlant"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-process
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="LBLPROCESS"
                  name="processCd"
                  v-model="tabParam.processCd"
                  @datachange="datachangeProcess"
                >
                </c-process>
                  <!-- @change="datachangeProcess" -->
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :afterIcon="
                    !disabled
                      ? [
                          {
                            name: 'mode_edit',
                            click: true,
                            callbackName: 'editWorkName',
                            color: '',
                          },
                          {
                            name: 'search',
                            click: true,
                            callbackName: 'searchWorkName',
                            color: 'teal',
                          },
                          {
                            name: 'close',
                            click: true,
                            callbackName: 'removeWorkName',
                            color: 'red',
                          },
                        ]
                      : null
                  "
                  required
                  :disabled="!isEdit"
                  :editable="editable"
                  :close="false"
                  label="LBLJOB"
                  name="sopName"
                  v-model="tabParam.sopName"
                  @editWorkName="editWorkName"
                  @searchWorkName="searchWorkName"
                  @removeWorkName="removeWorkName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  required
                  :editable="editable"
                  :disabled="disabled"
                  :haveProcess="true"
                  label="업체"
                  name="vendorCd"
                  v-model="tabParam.vendorCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="작업일"
                  type="date"
                  name="tbmWorkDate"
                  v-model="tabParam.tbmWorkDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 작업허가서의 업체감독자, 사업장 선택시 안전관리책임자 -->
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  label="작업책임자"
                  name="tbmWorkResponsibleId"
                  v-model="tabParam.tbmWorkResponsibleId"
                >
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <!-- 관련공사 -->
                <c-construnction
                  :disabled="disabled"
                  :editable="editable"
                  name="sopConstructionId"
                  v-model="tabParam.sopConstructionId">
                </c-construnction>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-project-location
                  :editable="editable"
                  :disabled="disabled"
                  label="작업장소"
                  name="sopMapId"
                  v-model="tabParam.sopMapId"
                />
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :disabled="disabled"
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="TBM_PRE_INSPECTION_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="사전점검"
                  name="preInspections"
                  v-model="tabParam.preInspections"
                >
                </c-checkbox>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업내용"
                  :rows="2"
                  name="tbmWorkContents"
                  v-model="tabParam.tbmWorkContents"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="특이사항"
                  :rows="2"
                  name="significant"
                  v-model="tabParam.significant"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="riskTable"
            title="위험요인 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="tabParam.tbmRiskHazardModelList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="위험요인을 추가하세요."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="tbmRiskId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="SOP에서 선택"
                  icon="add"
                  @btnClicked="addSopRisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="4M에서 선택"
                  icon="add"
                  @btnClicked="addJsaRisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="JRA에서 선택"
                  icon="add"
                  @btnClicked="addJsaRisk2"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADDDIRECTLY"
                  icon="add"
                  @btnClicked="addRisk"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    tabParam.tbmRiskHazardModelList.length > 0
                  "
                  :showLoading="false"
                  label="LBLEXCEPT"
                  icon="remove"
                  @btnClicked="removeRisk"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmJobStep'">
                <c-text-column
                  v-if="props.row['tbmPermitFlag'] === 'N'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmJobStep']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmJobStep']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmRiskHazard'">
                <c-text-column
                  v-if="props.row['tbmPermitFlag'] === 'N'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmRiskHazard']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmRiskHazard']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmImprovementMeasures'">
                <c-text-column
                  v-if="props.row['tbmPermitFlag'] === 'N'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmImprovementMeasures']"
                  @datachange="datachange(props)"
                />
                <span v-else>
                  {{props.row['tbmImprovementMeasures']}}
                </span>
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="교육자료">
          </c-upload>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="attendeeTable"
            title="TBM 참석자 목록"
            :columns="attendeeGrid.columns"
            :gridHeight="attendeeGrid.height"
            :data="tabParam.tbmAttendeeModelList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참석자가 없습니다."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="tbmAttendeeId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="isWorkPermit"
                  :showLoading="false"
                  label="참석자 불러오기"
                  icon="save_alt"
                  @btnClicked="workPermitLoadAttendee"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addAttendee"
                />
                <c-btn
                  v-if="
                    editable &&
                    !disabled &&
                    tabParam.tbmAttendeeModelList.length > 0
                  "
                  :showLoading="false"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="removeAttendee"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div> -->
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
import printJs from 'print-js'
import VueQrcode from '@chenfengyuan/vue-qrcode';
export default {
  name: "tbm-info",
  components: {
    VueQrcode
  },
  props: {
    tabParam: {
      type: Object,
      default() {
        return {
          tbmId: "",
          tbmNo: "",
          plantCd: "",
          permitNo: "",
          mdmSopId: "",
          sopName: "",
          sopMapId: '',
          mapName: '',
          tbmWorkResponsibleId: "",
          tbmWorkResponsibleName: "",
          tbmName: "",
          tbmWorkDate: "",
          tbmWorkArea: "",
          tbmWorkContents: "",
          significant: "",
          tbmCompleteFlag: 'N',
          processCd: "",
          vendorCd: "",
          sopWorkPermitId: "",
          preInspections: "",
          tbmWorkPermitFlag: 'N',
          sopConstructionId: '',

          tbmAttendeeModelList: [],
          tbmRiskHazardModelList: [],

          deleteTbmAttendeeModelList: [],
          deleteTbmRiskHazardModelList: [],
        };
      },
    },
    attachInfo: {
      type: Object,
      default() {
        return {
          isSubmit: '',
          taskClassCd: 'TBM_EDUCATION',
          taskKey: '',
        }
      }
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "tbmJobStep",
            field: "tbmJobStep",
            label: "작업단계",
            align: "left",
            style: "width:20%",
            type: "custom",
            sortable: false,
          },
          {
            name: "tbmRiskHazard",
            field: "tbmRiskHazard",
            label: "위험요인",
            align: "left",
            style: "width:30%",
            type: "custom",
            sortable: false,
          },
          {
            name: "tbmImprovementMeasures",
            field: "tbmImprovementMeasures",
            label: "안전대책",
            type: "custom",
            style: "width:40%",
            align: "left",
            sortable: false,
          },
          {
            name: "type",
            field: "type",
            label: "구분",
            style: "width:10%",
            align: "center",
            sortable: false,
          },
        ],
        height: "300px",
        data: [],
      },
      attendeeGrid: {
        columns: [
          {
            name: "tbmAttendeeUserName",
            field: "tbmAttendeeUserName",
            label: "참석자",
            required: true,
            align: "left",
            type: "text",
            style: "width:200px",
            sortable: false,
          },
          {
            name: "tbmAttendeeJobName",
            field: "tbmAttendeeJobName",
            label: "직책/직무",
            required: true,
            align: "left",
            type: "text",
            style: "width:200px",
            sortable: false,
          },
          {
            name: "remark",
            field: "remark",
            label: "비고",
            align: "left",
            type: "text",
            style: "width:480px",
            sortable: false,
          },
        ],
        height: "540px",
        data: [],
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      getUrl: "",
      saveUrl: '',
      completeUrl: '',
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getRespondUrl: '',
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: false,
      isApproval: false,
      serverName: '',
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isWorkPermit() {
      return (
        Boolean(this.tabParam.sopWorkPermitId) &&
        this.editable
      );
    },
    disabled() {
      return this.tabParam.tbmCompleteFlag === "Y" || this.tabParam.approvalStatusCd === 'ASC0000001';
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.tabParam.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.tabParam.approvalStatusCd, 
        apprEditable: !this.disabled && this.tabParam.tbmId, // 결재버튼 활성화 기준
        param: this.tabParam, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000018', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          tbmId: this.tabParam.tbmId,
          isApprContent: true
        },
        approvalRequestName: 'TBM ['+this.tabParam.tbmName+']', // 결재요청명 (문서 title)
        approvalConnId: this.tabParam.tbmId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  // * 작업허가서 있을때
  // - 프로젝트, 공정, 업체, 작업, 작업책임자(업체감독자), 작업장소, 작업일, 작업내용 전부 disabled
  // - 참석자 : 작업허가서의 작업자
  // * 작업허가서 없을때
  // - 사업장과 관련된 공정 선택
  // - 작업장소 : 사업장 > 작업장소
  // - 작업책임자 : 하도급 > 안전책임자
  // - 작업 : 공정, 업체에 맞는 작업 선택
  // - 업체 : 프로젝트와 공종에 맞는 하도급 선택
  // - 위험요인 목록  작업과 관련된 작업단계(직접추가 제외)
  // - 참석자 : 직영일때 사용자 추가(이름, 직책/직무 수정X), 업체일때 행추가(이름, 직책/직무 수정O) 
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.saveUrl = transactionConfig.sai.tbm.insert.url;
      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.getUrl = selectConfig.sai.tbm.get.url;
      this.getRespondUrl = selectConfig.sai.tbm.respond.url;
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.insertUrl = transactionConfig.sai.tbm.insert.url;
      this.updateUrl = transactionConfig.sai.tbm.update.url;
      this.completeUrl = transactionConfig.sai.tbm.complete.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.printUrl = selectConfig.sai.tbm.print.url;
      this.redirectUrl = selectConfig.com.mobileHostname.url;

      this.serverUrl();
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '20px',
        targetStyles: ['*'],
      })
    },
    saveTbm() {
      if (this.tabParam.tbmId) {
        this.saveUrl = this.updateUrl;
        this.saveType = "PUT";
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = "POST";
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "LBLCONFIRM",
            message: "MSGSAVE",
            // TODO : 필요시 추가하세요.
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tabParam.regUserId = this.$store.getters.user.userId;
              this.tabParam.chgUserId = this.$store.getters.user.userId;
              this.$_.forEach(this.tabParam.tbmRiskHazardModelList, item => {
                item.chgUserId = this.$store.getters.user.userId
              })
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    getDetail() {
      this.$emit("getDetail");
    },
    saveCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      if (!this.tabParam.tbmId) {
        this.$emit("updateMode", _result.data);
      } else {
        this.$emit("getDetail");
      }
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
    },
    completeTbm() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
            window.getApp.$emit("CONFIRM", {
            title: "LBLCONFIRM",
            message: "TBM을 완료하시겠습니까? 완료할 시 탭[ TBM 기본정보, 참석자 및 건강상태, 관련 기계 및 장비 ]을 수정 할 수 없습니다.",
            // TODO : 필요시 추가하세요.
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.tabParam.regUserId = this.$store.getters.user.userId;
              this.tabParam.chgUserId = this.$store.getters.user.userId;
              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    completeCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$emit("getDetail");
    },
    addSopRisk() {
      if (!this.tabParam.mdmSopId) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '작업을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      this.popupOptions.title = "SOP 검색"; // SOP 검색
      this.popupOptions.param = {
        type: "multiple",
        searchType: "4", //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        plantCd: this.tabParam.plantCd,
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/common/process/sopPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.tabParam.tbmRiskHazardModelList.splice(0, 0, {
            tbmId: this.tabParam.tbmId,
            tbmRiskId: uid(),
            tbmJobStep: _item.jobStepName,
            tbmRiskHazard: _item.hazardOccurReason,
            tbmImprovementMeasures: _item.hazardOccurResult,
            tbmSopFlag: "Y",
            tbmJsaFlag: "N",
            tbmDirectFlag: "N",
            tbmPermitFlag: "N",
            regUserId: this.$store.getters.user.userId,
            remarks: "",
            editFlag: "C",
            type: 'SOP'
          });
        })
      }
    },
    addJsaRisk() {
      this.popupOptions.title = '4M';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tabParam.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/fmAssessRiskRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAssessRiskRegisterPopPopup1;
    },
    addJsaRisk2() {
      this.popupOptions.title = 'JRA';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tabParam.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/jraAssessRiskRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAssessRiskRegisterPopPopup2;
    },
    closeAssessRiskRegisterPopPopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.tabParam.tbmRiskHazardModelList.splice(0, 0, {
            tbmId: this.tabParam.tbmId,
            tbmRiskId: uid(), 
            tbmJobStep: item.jobName, // 작업단계가 없기 때문에 작업으로 <<
            tbmRiskHazard: item.riskHazardName,
            tbmImprovementMeasures: item.addingRiskManagementActivities, // 안전대책은 추가 리스트관리 계획
            tbmSopFlag: "N",
            tbmJsaFlag: "Y",
            tbmDirectFlag: "N",
            tbmPermitFlag: "N",
            regUserId: this.$store.getters.user.userId,
            remarks: "",
            editFlag: "C",
            type: '위험등록부'
          });
        })
      }
    },
    closeAssessRiskRegisterPopPopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.tabParam.tbmRiskHazardModelList.splice(0, 0, {
            tbmId: this.tabParam.tbmId,
            tbmRiskId: uid(), 
            tbmJobStep: item.jobStepName, 
            tbmRiskHazard: item.riskHazardName,
            tbmImprovementMeasures: item.currentSafetyMeasures, // 안전대책은 현재안전조치
            tbmSopFlag: "N",
            tbmJsaFlag: "Y",
            tbmDirectFlag: "N",
            tbmPermitFlag: "N",
            regUserId: this.$store.getters.user.userId,
            remarks: "",
            editFlag: "C",
            type: '위험등록부'
          });
        })
      }
    },
    addRisk() {
      this.tabParam.tbmRiskHazardModelList.splice(0, 0, {
        tbmId: this.tabParam.tbmId,
        tbmRiskId: uid(),
        tbmJobStep: "",
        tbmRiskHazard: "",
        tbmImprovementMeasures: "",
        tbmSopFlag: "N",
        tbmJsaFlag: "N",
        tbmDirectFlag: "Y",
        tbmPermitFlag: "N",
        regUserId: this.$store.getters.user.userId,
        remarks: "",
        editFlag: "C",
        type: '직접입력'
      });
    },
    removeRisk() {
      let selectData = this.$refs["riskTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tabParam.deleteTbmRiskHazardModelList) {
            this.tabParam.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tabParam.deleteTbmRiskHazardModelList.push(item);
          }
          this.tabParam.tbmRiskHazardModelList = this.$_.reject(
            this.tabParam.tbmRiskHazardModelList,
            item
          );
        });
      }
    },
    // TBM 참석자 추가
    addAttendee() {
      // 직영일때
      if (this.tabParam.vendorCd === 'VEN0000000') {
        this.popupOptions.title = "TBM 참석자 검색"; // TBM 참석자 검색
        this.popupOptions.param = {
          type: "multiple",
        };
        this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
        this.popupOptions.width = "70%";
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeUserPopup;
        // 참석자 직접추가
      } else {
        this.tabParam.tbmAttendeeModelList.push({
          tbmId: this.tabParam.tbmId,
          tbmAttendeeId: uid(),
          tbmAttendeeUserId: "",
          tbmAttendeeUserName: "",
          tbmAttendeeJobName: "",
          healthStatusCd: "THS0000001",
          mentalFatigueFlag: "N",
          mentalPhychologicalFlag: "N",
          mentalPhysicalFlag: "N",
          physicalDefectFlag: "N",
          tbmDirectFlag: "Y",
          remark: "",
          regUserId: this.$store.getters.user.userId,
          editFlag: "C",
        });
      }
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.tbmAttendeeModelList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.tbmAttendeeModelList.push({
              tbmId: this.tabParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeUserName: _item.userName,
              tbmAttendeeJobName: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFatigueFlag: "N",
              mentalPhychologicalFlag: "N",
              mentalPhysicalFlag: "N",
              physicalDefectFlag: "N",
              tbmDirectFlag: "Y",
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tabParam.deleteTbmAttendeeModelList) {
            this.tabParam.deleteTbmAttendeeModelList = [];
          }
          if (
            this.$_.findIndex(this.tabParam.deleteTbmAttendeeModelList, {
              tbmAttendeeId: item.tbmAttendeeId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tabParam.deleteTbmAttendeeModelList.push(item);
          }
          this.tabParam.tbmAttendeeModelList = this.$_.reject(
            this.tabParam.tbmAttendeeModelList,
            item
          );
        });
      }
    },
    copyTbm() {
      this.popupOptions.title = "타 TBM 검색"; // SOP 검색
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"./tbmPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTbmPopup;
    },
    closeCopyTbmPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.tabParam.tbmRiskHazardModelList = [];
      if (data && data.length > 0) {
        if (data[0].tbmId) {
          this.$http.url = this.$format(this.getUrl, data[0].tbmId);
          this.$http.type = "GET";
          this.$http.request((_result) => {
            this.tabParam.plantCd = _result.data.plantCd;
            this.tabParam.permitNo = _result.data.permitNo;
            this.tabParam.mdmSopId = _result.data.mdmSopId;
            this.tabParam.sopName = _result.data.sopName;
            this.tabParam.sopMapId = _result.data.sopMapId;
            this.tabParam.tbmName = _result.data.tbmName;
            this.tabParam.tbmWorkResponsibleId =
              _result.data.tbmWorkResponsibleId;
            this.tabParam.tbmWorkResponsibleName =
              _result.data.tbmWorkResponsibleName;
            this.tabParam.tbmWorkDate = _result.data.tbmWorkDate;
            this.tabParam.tbmWorkArea = _result.data.tbmWorkArea;
            this.tabParam.tbmWorkContents = _result.data.tbmWorkContents;
            this.tabParam.tbmCompleteFlag = "N";
            this.tabParam.processCd = _result.data.processCd;
            this.tabParam.vendorCd = _result.data.vendorCd;
            this.tabParam.sopWorkPermitId = _result.data.sopWorkPermitId;
            this.tabParam.preInspections = _result.data.preInspections;
            this.tabParam.tbmAttendeeModelList =
              _result.data.tbmAttendeeModelList;
            this.tabParam.tbmRiskHazardModelList =
              _result.data.tbmRiskHazardModelList;
            this.tabParam.tbmHazardEquipModelList =
              _result.data.tbmHazardEquipModelList;
          });
        }
      }
    },
    printTbm() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.tabParam.tbmId);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = this.tabParam.tbmNo + ".xlsx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
    // 작업허가서 선택
    searchWorkPermit() {
      if (!this.tabParam.plantCd) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '사업장을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
      }
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.tabParam.plantCd, 
        popupMode: 'Y',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.tabParam.tbmAttendeeModelList = [];
      if (data && data.length > 0) {
      this.$http.url = this.$format(this.getWorkPermitUrl, data[0].sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tabParam.sopWorkPermitId = _result.data.sopWorkPermitId
        this.tabParam.permitNo = _result.data.permitNo // 허가번호
        this.tabParam.tbmWorkDate = _result.data.permitDate // 작업일
        this.tabParam.processCd = _result.data.processCd // 공정
        this.tabParam.vendorCd = _result.data.vendorCd // 업체
        this.tabParam.mdmSopId = _result.data.mdmSopId // 작업
        this.tabParam.sopMapId = _result.data.sopMapId // 작업장소
        this.tabParam.tbmWorkResponsibleId = _result.data.maintenanceDeptCheckUserId // 책임자
        this.tabParam.tbmWorkResponsibleName = _result.data.maintenanceDeptCheckUserName // 책임자
        this.tabParam.sopName = _result.data.sopName // 작업
        this.tabParam.tbmName = _result.data.sopName // TBM명
        this.tabParam.tbmWorkContents = _result.data.workSummary // 작업내용
        this.tabParam.tbmWorkPermitFlag = "Y" // 작업허가서 유 
        
        this.$_.forEach(this.tabParam.tbmRiskHazardModelList, (item) => {
          if (!this.tabParam.deleteTbmRiskHazardModelList) {
            this.tabParam.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tabParam.deleteTbmRiskHazardModelList.push(item);
          }
          this.tabParam.tbmRiskHazardModelList = this.$_.reject(
            this.tabParam.tbmRiskHazardModelList,
            item
          );
        });
        this.tabParam.tbmRiskHazardModelList = [];
        if (_result.data.workers && _result.data.workers.length > 0) {
          this.$_.forEach(_result.data.workers, _item => {
            let index = this.$_.findIndex(this.tabParam.tbmAttendeeModelList, {
              tbmAttendeeUserName: _item.workerName, 
              tbmAttendeeJobName: _item.jobName, 
            });
            if (index === -1) {
              this.tabParam.tbmAttendeeModelList.push({
                tbmId: this.tabParam.tbmId,
                tbmAttendeeId: uid(),
                tbmAttendeeUserId: _item.userId,
                tbmAttendeeUserName: _item.workerName,
                tbmAttendeeJobName: _item.jobName,
                healthStatusCd: "THS0000001",
                mentalFatigueFlag: "N",
                mentalPhychologicalFlag: "N",
                mentalPhysicalFlag: "N",
                physicalDefectFlag: "N",
                tbmWorkPermitFlag: "Y",
                regUserId: this.$store.getters.user.userId,
                editFlag: "C",
              })
            }
          })
        } 
        if (_result.data.assessments && _result.data.assessments.length > 0) {
          this.$_.forEach(_result.data.assessments, _item => {
            let index = this.$_.findIndex(this.tabParam.tbmRiskHazardModelList, {
              tbmJobStep: _item.jobStepName, 
              tbmRiskHazard: _item.riskHazardName, 
            });
            if (index === -1) {
              this.tabParam.tbmRiskHazardModelList.push({
                tbmId: this.tabParam.tbmId,
                tbmRiskId: uid(),
                tbmJobStep: _item.jobStepName,
                tbmRiskHazard: _item.riskHazardName,
                tbmImprovementMeasures: _item.safetyActionMeasures,
                tbmSopFlag: "N",
                tbmJsaFlag: "N",
                tbmDirectFlag: "N",
                tbmPermitFlag: "Y",
                regUserId: this.$store.getters.user.userId,
                remarks: "",
                editFlag: "C",
                type: '작업허가서'
              })
            }
          })
        } 
      },);
      }
    },
    removeWorkPermit() {
      window.getApp.$emit("CONFIRM", {
        title: "LBLCONFIRM",
        message:
            "해당 작업허가서에 지정된 기본 정보들과 작업자가 삭제됩니다.\n\r진행하시겠습니까?",
        // TODO : 필요시 추가하세요.
        type: "info", // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.sopWorkPermitId = '';
          this.tabParam.permitNo = '';
          this.tabParam.processCd = '';
          this.tabParam.mdmSopId = '';
          this.tabParam.sopName = '';
          this.tabParam.vendorCd = '';
          this.tabParam.vendorName = '';
          this.tabParam.tbmWorkDate = '';
          this.tabParam.tbmWorkResponsibleId = '';
          this.tabParam.tbmWorkResponsibleName = '';
          this.tabParam.mapName = '';
          this.tabParam.sopMapId = '';
          this.tabParam.tbmWorkContents = '';
          this.tabParam.tbmAttendeeModelList = [];
          this.$_.forEach(this.tabParam.tbmRiskHazardModelList, (item) => {
          if (!this.tabParam.deleteTbmRiskHazardModelList) {
            this.tabParam.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tabParam.deleteTbmRiskHazardModelList.push(item);
          }
          this.tabParam.tbmRiskHazardModelList = this.$_.reject(
            this.tabParam.tbmRiskHazardModelList,
            item
          );
        });
          this.tabParam.tbmRiskHazardModelList = [];
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // 작업 직접입력
    editWorkName() {
      this.isEdit = true;
      this.tabParam.mdmSopId = null;
    },
    // 작업 팝업 선택
    searchWorkName() {
      if (!this.tabParam.plantCd) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '사업장을 선택하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      this.popupOptions.title = "작업 선택"; // 작업 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.tabParam.plantCd,
        searchType: "1", //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/common/process/sopPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkNamePopup;
    },
    closeWorkNamePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      
      if (data && data.length > 0) {
        this.$_.forEach(this.tabParam.tbmRiskHazardModelList, (item) => {
          if (!this.tabParam.deleteTbmRiskHazardModelList) {
            this.tabParam.deleteTbmRiskHazardModelList = [];
          }
          if (
            this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.tabParam.deleteTbmRiskHazardModelList.push(item);
          }
          this.tabParam.tbmRiskHazardModelList = this.$_.reject(
            this.tabParam.tbmRiskHazardModelList,
            item
          );
        });
        this.tabParam.tbmRiskHazardModelList = [];
        this.tabParam.mdmSopId = data[0].mdmSopId;
        this.tabParam.sopName = data[0].sopName;
        this.isEdit = false;
      }
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'TBM을 삭제하시겠습니까? 삭제할 시 모든 정보가 삭제됩니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // plantCd (공정, 작업, 업체, 작업, 작업장소, 작업책임자, 위험요인)
    datachangePlant() {
      this.tabParam.processCd = '';
      this.tabParam.mdmSopId = '';
      this.tabParam.sopName = '';
      this.tabParam.vendorCd = '';
      this.tabParam.sopMapId = '';
      this.tabParam.tbmWorkResponsibleId = '';
      this.tabParam.tbmWorkResponsibleName = '';
      this.$_.forEach(this.tabParam.tbmRiskHazardModelList, (item) => {
        if (!this.tabParam.deleteTbmRiskHazardModelList) {
          this.tabParam.deleteTbmRiskHazardModelList = [];
        }
        if (
          this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
            tbmRiskId: item.tbmRiskId,
          }) === -1 &&
          item.editFlag !== "C"
        ) {
          this.tabParam.deleteTbmRiskHazardModelList.push(item);
        }
        this.tabParam.tbmRiskHazardModelList = this.$_.reject(
          this.tabParam.tbmRiskHazardModelList,
          item
        );
      });
      this.tabParam.tbmRiskHazardModelList = [];
    },
    // 공정 삭제 (작업, 업체, 작업책임자, 위험요인)
    datachangeProcess() {
      this.tabParam.mdmSopId = '';
      this.tabParam.sopName = '';
      this.$_.forEach(this.tabParam.tbmRiskHazardModelList, (item) => {
        if (!this.tabParam.deleteTbmRiskHazardModelList) {
          this.tabParam.deleteTbmRiskHazardModelList = [];
        }
        if (
          this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
            tbmRiskId: item.tbmRiskId,
          }) === -1 &&
          item.editFlag !== "C"
        ) {
          this.tabParam.deleteTbmRiskHazardModelList.push(item);
        }
        this.tabParam.tbmRiskHazardModelList = this.$_.reject(
          this.tabParam.tbmRiskHazardModelList,
          item
        );
      });
      this.tabParam.tbmRiskHazardModelList = [];
    },
    // 작업 삭제 (위험요인만)
    removeWorkName() {
      this.tabParam.mdmSopId = '';
      this.tabParam.sopName = '';
      this.$_.forEach(this.tabParam.tbmRiskHazardModelList, (item) => {
        if (!this.tabParam.deleteTbmRiskHazardModelList) {
          this.tabParam.deleteTbmRiskHazardModelList = [];
        }
        if (
          this.$_.findIndex(this.tabParam.deleteTbmRiskHazardModelList, {
            tbmRiskId: item.tbmRiskId,
          }) === -1 &&
          item.editFlag !== "C"
        ) {
          this.tabParam.deleteTbmRiskHazardModelList.push(item);
        }
        this.tabParam.tbmRiskHazardModelList = this.$_.reject(
          this.tabParam.tbmRiskHazardModelList,
          item
        );
      });
      this.tabParam.tbmRiskHazardModelList = [];
    },
    // 업체 삭제 (작업책임자)
    dataChangeVendor(data) {
      if (!data) {
        this.tabParam.vendorCd = '';
        this.tabParam.tbmWorkResponsibleId = '';
        this.tabParam.tbmWorkResponsibleName = '';
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    /* eslint-disable no-unused-vars */ 
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId

          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
  },
};
</script>